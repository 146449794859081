import React from 'react';





function About() {
  
  return(
	        <div className="brand-area brand-bg" id='about'>
        <div className="container">
          <div data-aos="zoom-in" className="slider-content text-center">

          <h2>O NAS</h2>

          </div>
          
          
        </div>
      </div>
  )
}

export default About;
