import React from 'react';

function AboutUsArea() {


  return (
	  <section className="about-us-area pt-90 pb-120">

          <div className="container">
            <div className="row">
              <div className="col-lg-6 order-0 order-lg-2">
                <div className="about-img">
                <img src="assets/img/images/elektryka.png" alt="Elektryka Samochodowa" />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="about-content">
                  <p> Specjalizujemy się w kompleksowych naprawach elektroniki samochodowej i układów elektrycznych.</p>
                  <p>Zajmujemy się także elektroniką pojazdową, diagnostyką komputerową i programowaniem ECU.
 Dzięki naszemu doświadczeniu i zaawansowanym narzędziom wykonujemy nawet najtrudniejsze naprawy, których nie jest w stanie zapewnić konkurencja.
</p>
                </div>
              </div>
            </div>
          </div>

          <div className="container">
            <div className="row">
              <div className="col-lg-6 order-0">
                <div className="about-img">
                <img src="assets/img/images/elektronika.png" alt="Elektronika samochodowa" />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="about-content title-style-for  h2">
                  <p> Nasz zespół tworzą wykwalifikowani fachowcy dysponujący najnowocześniejszą technologią i metodami napraw.                  </p>
                  <p> Gwarantujemy profesjonalne podejście, trafną diagnozę i skuteczne rozwiązanie wszelkich problemów związanych z elektroniką pojazdu i układami elektrycznymi.
                  </p>
                  
                  <h2><span> NAPISZ DO NAS ALBO ZADZWOŃ NA PEWNO ODPOWIEMY!</span></h2>
                  <h5><span> Masz firmę i szukasz współpracy? Daj nam znać! :)</span></h5>
                </div>
              </div>
            </div>
          </div>



        </section>
        
  )
}

export default AboutUsArea;
