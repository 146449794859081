import React from 'react';





function Contact() {
  
  return(
	        <div className="brand-area brand-bg2"  id="contact">
        <div className="container">
          <div data-aos="zoom-in" className="slider-content text-center">

          <h2 >Kontakt</h2>

          </div>
          
          
        </div>
      </div>
  )
}

export default Contact;
