import React from 'react';

function Footer() {
  return (
	   <footer>
        <div className="footer-top footer-bg">
          {/* banner info */}
          <div className="newsletter-area">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div className="newsletter-wrap">
                    <div className="section-title newsletter-title">
                      <h2> <span>INFO</span></h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* banner info */}
          <div className="container">
            <div className="row justify-content-between">
              <div className="col-xl-4 col-lg-4 col-md-6">
                <div className="footer-widget mb-50">
                  <div className="footer-logo mb-35">
                    <a href="#hero"><img src="assets/img/logo/logo.png" alt="logo firmy StECU - profesjonalnej firmy zajmującej się naprawą elektroniki oraz elektryki samochodowej" /></a>
                  </div>
                  <div className="footer-text">
                    <div className="footer-contact">
                      <ul>
                        <li><i className="fas fa-map-marker-alt" /><span>Adres : </span> Zientary Malewskiej 28a Olsztyn</li>
                        <li><i className="fas fa-headphones" /><span>Telefon : </span>+48 783 684 400</li>
                        <li><i className="fas fa-envelope-open" /><span>Email : </span> serwis@stecu.com.pl</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-lg-3 col-sm-6">
                <div className="footer-widget mb-50">
                  <div className="fw-title mb-35">
                    <h5>Sekcje</h5>
                  </div>
                  <div className="fw-link">
                    <ul>
                      <li><a  href="#hero">Strona Główna</a></li>                     
                      <li><a  href="#about">O Nas</a></li>
                      <li><a  href="#services">Oferta</a></li>
                      <li><a  href="#contact">Kontakt</a></li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-lg-3 col-sm-6">
                <div className="footer-widget mb-50">
                  <div className="fw-title mb-35">
                    <h5>Godziny otwarcia:</h5>
                  </div>
                  <div className="fw-link">
                    <ul>
                      <li><a  >PN - PT: 8.00-16.00</a></li>
                      <li><a  >Sobota: NIECZYNNE</a></li>
                      <li><a  >Niedziela: NIECZYNNE</a></li>
                    </ul>
                  </div>
                </div>
              </div>
              <div id="fb-root"></div>

              {/* <div className="col-xl-4 col-lg-4 col-md-6">
                <div className="footer-widget mb-50">
                  <div className="fw-title mb-35">
                    <h5>Śledź NAS:</h5>
                  </div>
                  <div className="footer-social">
                    <ul>

                    </ul>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
        <div className="copyright-wrap">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <div className="copyright-text">
                  <p>Copyright © 2024 <a  href="#hero">StECU</a> Wszelkie prawa zastrzeżone.</p>
                </div>
              </div>

            </div>
          </div>
        </div>
      </footer>
  )
}

export default Footer;
