import { BrowserRouter as Router, Switch} from 'react-router-dom';
import Index from './components/index/indexone/Index';
import ScrollToTopRoute from './components/scrolltotop/ScrollToTopRoute';

// import Loader from "react-js-loader";





function App() {

  return (
    
    <div className="App">
      
     <Router>
       
        <Switch>
        <ScrollToTopRoute exact={true} path='/'>
        <Index/>
        </ScrollToTopRoute>
     </Switch>
     </Router>
     
    </div>
  );
}

export default App;
