import React,{useEffect} from 'react';
import {Link} from 'react-router-dom';
import $ from 'jquery';
import ScrollToTop from "react-scroll-to-top";


function HeaderOne() {
  
  useEffect(()=>{
    //SubMenu Dropdown Toggle
      if ($('.menu-area li.menu-item-has-children ul').length) {
        $('.menu-area .navigation li.menu-item-has-children').append('<div class="dropdown-btn"><span class="fas fa-angle-down"></span></div>');

      }

      //Mobile Nav Hide Show
      if ($('.mobile-menu').length) {

        var mobileMenuContent = $('.menu-area .push-menu').html();
        $('.mobile-menu .menu-box .menu-outer').append(mobileMenuContent);

        //Dropdown Button
        $('.mobile-menu li.menu-item-has-children .dropdown-btn').on('click', function () {
          $(this).toggleClass('open');
          $(this).prev('ul').slideToggle(500);
        });


        $('.menu-backdrop, .mobile-menu .close-btn').click (()=>{
          $('body').removeClass('mobile-menu-visible');
        })


        //Menu Toggle Btn
        $('.mobile-nav-toggler').on('click', function () {
          $('body').addClass('mobile-menu-visible');
        });
      }

  },[]);

 useEffect(()=>{
    $(window).on('scroll', function () {
      var scroll = $(window).scrollTop();
      if (scroll < 245) {
        $("#sticky-header").removeClass("sticky-menu");
        $('.scroll-to-target').removeClass('open');
    
      } else {
        $("#sticky-header").addClass("sticky-menu");
        $('.scroll-to-target').addClass('open');
      }
    });
    
  },[])
  

  return (
	  <header>
       <ScrollToTop smooth top="500" color="#000" />
        <div id="sticky-header" className="main-header menu-area">
          <div className="container-fluid container-full-padding">
            <div className="row">
              <div className="col-12">
              <div className="mobile-nav-toggler"><i className="fas fa-bars" /></div>
                <div className="main-menu">
                  <nav>
                    <div data-aos="fade-right" className=" logo">
                      <Link to='#hero'><img src="assets/img/logo/logo.png" alt="logo firmy StECU - profesjonalnej firmy zajmującej się naprawą elektroniki oraz elektryki samochodowej" /></Link>
                    </div>
                   
                    <div className="navbar-wrap push-menu d-none d-lg-flex">
                      <ul className="navigation">
                        <li  className="navbar-link"><a href="#hero">Strona Główna</a>
                        </li>
                        <li className="navbar-link"><a href="#about">O Nas</a></li>
                        <li className="navbar-link"><a href="#services">Oferta</a>
                        </li>
                        
                        

                        <button 
  className="btncyber" 
><a href="#contact">
  <span useRef="#contact" className="btncyber__content">KONTAKT</span>
  <span className="btncyber__glitch"></span>
  <span className="btncyber__label">Do NAS</span></a>
</button>

                      </ul>
                    </div>
                  </nav>
                </div>
               <div className="mobile-menu">
                  <nav className="menu-box">
                    <div className="close-btn"><i className="fas fa-times" /></div>
                    <div className="nav-logo"><Link to="/"><img src="assets/img/logo/logo.png" alt="logo firmy StECU - profesjonalnej firmy zajmującej się naprawą elektroniki oraz elektryki samochodowej" title='true' /></Link>
                    </div>
                    <div className="menu-outer">
                  
                    </div>
                  </nav>
                </div>
                <div className="menu-backdrop" />
              </div>

            </div>
          </div>
        </div>
      </header>






  )
}
export default HeaderOne;
