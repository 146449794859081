import React from 'react';



function Brand() {
  
  return(
	        <div  className="brand-area brand-bg" id='services'>
        <div className="container">
          <div data-aos="zoom-in-up" className="slider-content text-center">

          <h2 >OFERTA</h2>

          </div>
          
          
        </div>
      </div>
  )
}

export default Brand;
