import React from 'react'
function Services() {

  
  return (
	 <section className="just-gamers-area just-gamers-bg pt-115 pb-120" >
            <div className="container">
              <div className="row  mb-70">
                <div className="col-xl-4 col-lg-5 col-md-10">
                  <div data-aos="fade-right" className=" section-title title-style-three  mb-70 ">
                    <h2>ELEKTRONIKA <span>SAMOCHODOWA</span></h2>
                    
                  </div>
                  <div className="just-gamers-list">
                    <ul>
                    <li>
                        <div className="just-gamers-list-icon just-gamers-list-icon-left">
                          <img src="assets/img/icon/a1.png" alt="" />
                        </div>
                        <div className="just-gamers-list-content fix">
                          <h5>Diagnostyka Elektroniki</h5>
                          <p>Pełna diagnostyka elektroniki twojego samochodu.</p>
                        </div>
                      </li>
                      <li>
                        <div className="just-gamers-list-icon just-gamers-list-icon-left">
                          <img src="assets/img/icon/a2.png" alt="" />
                        </div>
                        <div className="just-gamers-list-content fix">
                          <h5>Komputery pokładowe (ECU)</h5>
                          <p>Naprawa lub wymiana jednostki sterującej.</p>
                        </div>
                      </li>

                      <li>
                        <div className="just-gamers-list-icon just-gamers-list-icon-left">
                          <img src="assets/img/icon/a3.png" alt="" />
                        </div>
                        <div className="just-gamers-list-content fix">
                          <h5>Systemy bezpieczeństwa</h5>
                          <p>Diagnoza i naprawa systemów ABS, ESP, czy poduszek powietrznych.</p>

                        </div>
                      </li>
                      <li>
                        <div className="just-gamers-list-icon just-gamers-list-icon-left">
                          <img src="assets/img/icon/a4.png" alt="" />
                        </div>
                        <div className="just-gamers-list-content fix">
                          <h5>Systemy multimedialne</h5>
                          <p>Naprawa wyświetlaczy, radia, systemów nawigacyjnych.</p>

                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                 <div className="col-xl-4 col-lg-2 d-none d-lg-block mb-50 mt-50">
                  <div className="just-gamers-img ">
                    <img src="assets/img/images/camry.png" alt="" className="just-gamers-character" />
                  </div>
                </div> 
                <div className="col-xl-4 col-lg-5 col-md-10 ">
                  <div data-aos="fade-left" className="section-title title-style-three  mb-70 ">
                    <h2>ELEKTRYKA <span>SAMOCHODOWA</span></h2>
                  </div>
                  <div className="just-gamers-list">
                    <ul>
                    <li>
                        <div className="just-gamers-list-icon just-gamers-list-icon-right">
                          <img src="assets/img/icon/b1.png" alt="" />
                        </div>
                        <div className="just-gamers-list-content fix">
                          <h5>Diagnostyka Elektryki</h5>
                          <p>Pełna diagnostyka elektryki twojego samochodu.</p>
      
                        </div>
                      </li>
                      <li>
                        <div className="just-gamers-list-icon just-gamers-list-icon-right">
                          <img src="assets/img/icon/b2.png" alt="" />
                        </div>
                        <div className="just-gamers-list-content fix">
                          <h5>Oświetlenie</h5>
                          <p>Wymiana żarówek, reflektorów i tylnych świateł.</p>
      
                        </div>
                      </li>
                      <li>
                        <div className="just-gamers-list-icon just-gamers-list-icon-right">
                          <img src="assets/img/icon/b3.png" alt="" />
                        </div>
                        <div className="just-gamers-list-content fix">
                          <h5>Układ zapłonu</h5>
                          <p>Naprawa układu odpowiadającego za odpalenie samochodu.</p>
         
                        </div>
                      </li>
                      <li>
                        <div className="just-gamers-list-icon just-gamers-list-icon-right">
                          <img src="assets/img/icon/b4.png" alt="" />
                        </div>
                        <div className="just-gamers-list-content fix">
                          <h5>Instalacja elektryczna</h5>
                          <p>Naprawa uszkodzonych przewodów i złączy.</p>
                 
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>


              <div className="row">
                
                <div className="col-xl-12 col-lg-12 col-md-10  mb-70">
                  <div data-aos="zoom-in" className="section-title title-style-three  ">
                    <h2>Naprawiamy również sprzęt elektroniczny!</h2>
                  </div>
                  <div className="just-gamers-list">
                    <ul>

                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </section>
  )
}

export default Services