import React from 'react';
import Stecubanner from './Stecubanner';
import Brand from './Brand';
import Services from './Services';
import Header from '../../header/Header';
import Footer from '../../footer/Footer';
import AboutUs from './AboutUs';
import Contact from './Contact';
import ContactUS from './ContactUS';
import About from './About';
import AOS from 'aos';
import 'aos/dist/aos.css';

function IndexOne() {
  return (
    <>
        <Header/>
        <main>
          <Stecubanner/>
          <About/>
          <AboutUs/>
          <Brand/>
          <Services/>
          <Contact/>
          <ContactUS/>
        </main>
        <Footer/>
    </>
  )
}

export default IndexOne;
AOS.init();