import React from 'react';

function ContactArea() {

  return (
	<section className="contact-area pt-120 pb-120"   >
        <div className="container">
          <div className="row">
             <div className="col-lg-6">
             <div className="section-title title-style-three mb-20">
                <h2> <span>Siedziba Firmy</span></h2>
                <p>Dojazd od ulicy Zientary Malewskiej! Przez PKP Cargo, tuż przed wieżą wodną.</p>
              </div>
              <div id="contact-map" >
                <a href="https://www.google.com/maps/dir//StECU/data=!4m6!4m5!1m1!4e2!1m2!1m1!1s0x46e279d11419a29b:0x3736a387b1ac58ee?sa=X&ved=1t:3061&hl=pl&gl=pl&ictx=111">
              <img className="img-fluid"src="assets/img/images/MAP.png" alt="Mapa Dojazdu" /></a>
              
              </div> 
            </div> 
            <div className="col-lg-6 pl-45">
              
              <div className="section-title title-style-three mb-20">
                <h2>NAPISZ DO NAS <span> LUB ZADZWOŃ</span></h2>
              </div>
              <div className="contact-info-list mb-40">
                <ul>
                  <li><i className="fas fa-map-marker-alt" />Adres: Zientary Malewskiej 28a Olsztyn</li>
                  <li><i className="fas fa-info" />NIP: 7394000757</li>
                  <li><i className="fas fa-envelope" />Email: serwis&#64;stecu&#46;com.pl</li>
                  <li><i className="fas fa-phone" />NR TEL: +48 783 684 400  </li>
              
                </ul>
              </div>
              {/* <div className="contact-form">
                <form>
                <div className="row">
                    <div className="col-md-6">
                      <input type="text" placeholder="Twoje Imię" />
                    </div>
                    <div className="col-md-6">
                      <input type="email" placeholder="Twój Email" />
                    </div>
                  </div>
                  <textarea name="message" id="message" placeholder="Treść wiadomości..." defaultValue={""} />

                  <button>WYŚLIJ WIADOMOŚĆ</button>
                </form>
              </div> */}
            </div>
          </div>
        </div>


      </section>
  )
}

export default ContactArea



