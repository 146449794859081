import React from 'react';



function Stecubanner() {

  return (
	  <section className="slider-area slider-bg" id='hero'>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-6 col-lg-5 col-md-10">
              <div data-aos="zoom-in" className="slider-content text-center ">
                <div className="tlt fix" data-in-effect="fadeInLeft" ><img className="img-fluid" src="assets/img/logo/logo2.png" alt="" /></div>

                <i className="fas fa-map-marker-alt" /><p>OLSZTYN</p>
                <p className="wow fadeInUp" data-wow-delay="2s">PROFESJONALNA NAPRAWA ELEKTRONIKI I ELEKTRYKI SAMOCHODOWEJ I NIE TYLKO!</p>
                <button  className='sliderbutton2'onClick={(e) => {
e.preventDefault();
window.location.href='#services';
}}>SPRAWDŹ OFERTĘ!</button>
              </div>
            </div>
            <div className="col-xl-6 col-lg-5 col-md-10">
             
            </div>
          </div>
        </div>
      </section>
  )
}

export default Stecubanner;
